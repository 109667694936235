import { ContentWrapper, Surface, Typography } from "@isaaczafuta/ui";
import React from "react";
import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";
import { Renderer } from "jscad-react";
// @ts-ignore
import serializer from "@jscad/stl-serializer";
import { printableObjects } from "..";
import { Link } from "react-router-dom";

export const Printing: React.FC = () => {
  const geometry = printableObjects[0].solids;
  const rawData = serializer.serialize({ binary: true }, geometry);
  const blob = new Blob(rawData, { type: "model/stl" });
  const url = URL.createObjectURL(blob);

  return (
    <Page>
      <NavigationBar gutterBottom />
      <ContentWrapper small>
        {printableObjects.map((object) => (
          <Surface>
            <Renderer
              solids={object.solids}
              options={{ gridOptions: { show: false } }}
            />
            <Link to={`/printing/${object.slug}`}>Go</Link>
            <Typography>
              <a href={url} download={`${printableObjects[0].name}.stl`}>
                Download
              </a>
            </Typography>
          </Surface>
        ))}
        <div></div>
      </ContentWrapper>
    </Page>
  );
};
