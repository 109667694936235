import { useContext } from "react";

import { AuthenticatedUserContext } from "../context/authenticated-user-context";

export const useAuthenticatedUser = () =>
  useContext(AuthenticatedUserContext).user;

export const useRequiredAuthentatedUser = () => {
  return useAuthenticatedUser()!;
};
