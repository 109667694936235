import { css } from "@emotion/css";
import { Button, ContentWrapper, Typography, useTheme } from "@isaaczafuta/ui";
import { useEffect, useState } from "react";
import { GoX } from "react-icons/go";

import { GoPlus } from "react-icons/go";
import {
  getPickleballCredentials,
  getPickleballReservations,
  PickleballCredentials,
  PickleballReservation,
} from "../../api";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";
import { PickleballPaddle } from "../../images/illustrations";
import { AddReservationDialog } from "./dialogs/AddReservationDialog";
import { ClearCredentialsDialog } from "./dialogs/ClearCredentialsDialog";
import { SetCredentialsDialog } from "./dialogs/SetCredentialsDialog";
import { DeleteReservationDialog } from "./dialogs/DeleteReservationDialog";

interface HeadingProps {
  credentials: PickleballCredentials | null | undefined;
  onSetCredentials(): void;
  onRemoveCredentials(): void;
}

const Heading: React.FC<HeadingProps> = ({
  credentials,
  onSetCredentials,
  onRemoveCredentials,
}) => {
  const { spacing } = useTheme();

  const buttonText = credentials
    ? "Clear Pickleball Credentials"
    : "Set Pickleball Credentials";

  const handleClick = () => {
    if (credentials) {
      onRemoveCredentials();
    } else {
      onSetCredentials();
    }
  };

  return (
    <div
      className={css`
        display: flex;
        gap: ${spacing.medium};
        align-items: center;
        justify-content: flex-start;
        margin-bottom: ${spacing.large};
      `}
    >
      <PickleballPaddle width="150px" height="150px" />
      <div
        className={css`
          flex: 1;
        `}
      >
        <Typography variant="h4" gutterBottom>
          Pickle Grabber
        </Typography>
        <Typography gutterBottom>
          Automatically reserves pickleball courts.
        </Typography>
      </div>
      <div>
        <Button onClick={handleClick}>{buttonText}</Button>
      </div>
    </div>
  );
};

interface ReservationTableProps {
  reservations: PickleballReservation[] | undefined;
  onReservationDeleted(reservation: PickleballReservation): void;
}

const ReservationTable: React.FC<ReservationTableProps> = ({
  reservations,
  onReservationDeleted,
}) => (
  <div
    className={css`
      display: flex;
    `}
  >
    <table
      className={css`
        flex: 1;
      `}
    >
      <thead>
        <tr>
          <td width="25%">Day</td>
          <td width="25%">Time</td>
          <td width="25%">Duration</td>
          <td width="25%"></td>
        </tr>
      </thead>
      <tbody>
        {reservations &&
          reservations.map((reservation) => (
            <tr key={reservation.id.toString()}>
              <td>{reservation.dayOfWeek}</td>
              <td>{reservation.time}</td>
              <td>{reservation.duration}</td>
              <td>
                <Button
                  fullWidth
                  onClick={() => onReservationDeleted(reservation)}
                  icon={<GoX />}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

const usePickleballData = () => {
  const [credentials, setCredentials] = useState<
    PickleballCredentials | null | undefined
  >(undefined);
  const [reservations, setReservations] = useState<
    PickleballReservation[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const loadCredentials = async () => {
      const response = await getPickleballCredentials();
      switch (response.status) {
        case "success":
          setCredentials(response.data.credentials);
          break;
        case "error":
          setError(response.message);
          break;
      }
    };

    const loadInitialSchedule = async () => {
      const response = await getPickleballReservations();
      switch (response.status) {
        case "success":
          setReservations(response.data.reservations);
          setLoading(false);
          break;
        case "error":
          setError(response.message);
          break;
      }
    };

    const loadInitialData = async () => {
      try {
        await loadCredentials();
        await loadInitialSchedule();
      } catch (e) {}
    };

    loadInitialData();
  }, []);

  return {
    credentials,
    setCredentials,
    reservations,
    setReservations,
    loading,
    error,
  };
};

export const PickleGrabber: React.FC = () => {
  const {
    credentials,
    setCredentials,
    reservations,
    setReservations,
    loading,
    error,
  } = usePickleballData();

  const [addCredentialsDialogOpen, setAddCredentialsDialogOpen] =
    useState(false);
  const [clearCredentialsDialogOpen, setClearCredentialsDialogOpen] =
    useState(false);
  const [addReservationDialogOpen, setAddReservationDialogOpen] =
    useState(false);
  const [deletingReservation, setDeletingReservation] = useState<
    PickleballReservation | undefined
  >(undefined);

  const handleSetCredentialsFinished = (credentials: PickleballCredentials) => {
    setAddCredentialsDialogOpen(false);
    setCredentials(credentials);
  };

  const handleClearCredentialsFinished = () => {
    setClearCredentialsDialogOpen(false);
    setCredentials(null);
  };

  const handleAddFinished = (reservation: PickleballReservation) => {
    setReservations([...(reservations || []), reservation]);
    setAddReservationDialogOpen(false);
  };

  const handleOnReservationDeleted = (reservation: PickleballReservation) =>
    setDeletingReservation(reservation);

  const handleReservationDeletionFinished = () => {
    setReservations(
      reservations!.filter((r) => r.id !== deletingReservation!.id)
    );
    setDeletingReservation(undefined);
  };

  return (
    <Page>
      <NavigationBar loading={loading} />
      <ContentWrapper>
        {addCredentialsDialogOpen && (
          <SetCredentialsDialog
            defaultUsername={credentials?.username}
            onFinished={handleSetCredentialsFinished}
            onCancel={() => setAddCredentialsDialogOpen(false)}
          />
        )}
        {clearCredentialsDialogOpen && (
          <ClearCredentialsDialog
            username={credentials!.username}
            onFinished={handleClearCredentialsFinished}
            onCancel={() => setClearCredentialsDialogOpen(false)}
          />
        )}
        {addReservationDialogOpen && (
          <AddReservationDialog
            onFinished={handleAddFinished}
            onCancel={() => setAddReservationDialogOpen(false)}
          />
        )}
        {deletingReservation && (
          <DeleteReservationDialog
            reservation={deletingReservation}
            onFinished={handleReservationDeletionFinished}
            onCancel={() => setDeletingReservation(undefined)}
          />
        )}
        {!loading && (
          <Heading
            credentials={credentials}
            onRemoveCredentials={() => setClearCredentialsDialogOpen(true)}
            onSetCredentials={() => setAddCredentialsDialogOpen(true)}
          />
        )}
        {!loading && credentials && (
          <ContentWrapper small>
            {reservations && reservations.length > 0 ? (
              <ReservationTable
                reservations={reservations}
                onReservationDeleted={handleOnReservationDeleted}
              />
            ) : (
              <Typography textAlign="center">
                You currently have no scheduled reservations
              </Typography>
            )}
            <br />
            <Button
              onClick={() => setAddReservationDialogOpen(true)}
              icon={<GoPlus />}
              fullWidth
            >
              Add to Schedule
            </Button>
          </ContentWrapper>
        )}
      </ContentWrapper>
    </Page>
  );
};
