import { primitives, extrusions, booleans } from "@jscad/modeling";

export const roundedPancake = (
  radius: number,
  height: number,
  segments: number = 30
) => {
  const bodySize = radius - height / 2;

  const cap = primitives.circle({
    center: [bodySize, 0],
    radius: height / 2,
  });
  const body = primitives.rectangle({
    center: [bodySize / 2, 0],
    size: [bodySize, height],
  });

  const profile = booleans.union([cap, body]);

  return extrusions.extrudeRotate({ segments }, profile);
};
