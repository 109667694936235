import { Dialog, Select, Typography } from "@isaaczafuta/ui";
import { useState } from "react";
import {
  PickleballReservation,
  addPickleballReservation,
  PickleReservationDuration,
} from "../../../api";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;
const times = [
  "07:00:00",
  "07:30:00",
  "08:00:00",
  "08:30:00",
  "09:00:00",
  "09:30:00",
  "10:00:00",
  "10:30:00",
  "11:00:00",
  "11:30:00",
  "12:00:00",
  "12:30:00",
  "13:00:00",
  "13:30:00",
  "14:00:00",
  "14:30:00",
  "15:00:00",
  "15:30:00",
  "16:00:00",
  "16:30:00",
  "17:00:00",
  "17:30:00",
  "18:00:00",
  "18:30:00",
  "19:00:00",
  "19:30:00",
  "20:00:00",
  "20:30:00",
  "21:00:00",
  "21:30:00",
] as const;
const durations = ["60 Minutes", "90 Minutes"] as PickleReservationDuration[];

interface AddReservationDialogProps {
  onFinished(newReservation: PickleballReservation): void;
  onCancel(): void;
}

export const AddReservationDialog: React.FC<AddReservationDialogProps> = ({
  onCancel,
  onFinished,
}) => {
  const [dayOfWeek, setDayOfWeek] = useState<typeof daysOfWeek[number]>(
    daysOfWeek[0]
  );
  const [time, setTime] = useState<typeof times[number]>(times[0]);
  const [duration, setDuration] = useState<typeof durations[number]>(
    durations[0]
  );

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await addPickleballReservation(dayOfWeek, time, duration);
    switch (response.status) {
      case "success":
        onFinished(response.data.reservation);
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Add scheduled reservation"
      cancelEnabled={!submitting}
      confirmEnabled={!submitting}
      error={error}
    >
      <Typography gutterBottom>
        Select the day of week, time, and duration to be automatically reserved.
      </Typography>
      <br />
      <Select
        disabled={submitting}
        label="Day of week"
        value={dayOfWeek}
        options={daysOfWeek.map((d) => ({
          value: d,
          label: d,
        }))}
        onChange={(value) => setDayOfWeek(value)}
        gutterBottom
      />

      <Select
        disabled={submitting}
        label="Time"
        value={time}
        options={times.map((t) => ({
          value: t,
          label: t,
        }))}
        onChange={(value) => setTime(value)}
        gutterBottom
      />

      <Select
        disabled={submitting}
        label="Duration"
        value={duration}
        options={durations.map((t) => ({
          value: t,
          label: t,
        }))}
        onChange={(value) => setDuration(value)}
        gutterBottom
      />
    </Dialog>
  );
};
