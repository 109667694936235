import { Button, ContentWrapper, Typography } from "@isaaczafuta/ui";
import { Temporal } from "@js-temporal/polyfill";
import { useState } from "react";

import { BudgetAllocation, User, BudgetExpense } from "../../api";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";
import { useRequiredAuthentatedUser } from "../../hooks/use-authenticated-user";
import { formatCurrency } from "../../utils/format-currency";
import { ExpenseTable } from "./components/ExpenseTable";
import { AddBudgetExpenseDialog } from "./dialogs/AddBudgetExpenseDialog";
import { DeleteBudgetExpenseDialog } from "./dialogs/DeleteBudgetExpenseDialog";
import { SetBudgetAllocationDialog } from "./dialogs/SetBudgetAllocationDialog";
import { useBudgetData } from "./hooks/useBudgetData";

const totalAllocation = (
  user: User,
  allocations: BudgetAllocation[]
): number => {
  const todayISO = Temporal.Now.plainDateISO(user.timezone);

  return allocations.reduce((acc, allocation) => {
    const startDate = Temporal.PlainDate.from(allocation.startDateISO);
    const endDate = Temporal.PlainDate.from(
      allocation.endDateISO === "infinity" ? todayISO : allocation.endDateISO
    );
    const durationDays = endDate.since(startDate).days + 1;
    const allocationAmount = allocation.amountCents * durationDays;
    return acc + allocationAmount;
  }, 0);
};

const currentAllocation = (allocations: BudgetAllocation[]) => {
  const allocation = allocations.find(
    (allocation) => allocation.endDateISO === "infinity"
  )!;
  return allocation.amountCents;
};

const totalSpent = (expenses: BudgetExpense[]) => {
  return expenses.reduce((acc, expense) => {
    return acc + expense.amountCents;
  }, 0);
};

const remainingBudget = (
  user: User,
  allocations: BudgetAllocation[],
  expenses: BudgetExpense[]
) => {
  return totalAllocation(user, allocations) - totalSpent(expenses);
};

export const Budget: React.FC = () => {
  const user = useRequiredAuthentatedUser();
  const { data, addBudgetExpense, setBudgetAllocations, deleteBudgetExpense } =
    useBudgetData();
  const [setBudgetAllocationDialogOpen, setSetBudgetAllocationDialogOpen] =
    useState(false);
  const [addBudgetExpenseDialogOpen, setAddBudgetExpenseDialogOpen] =
    useState(false);
  const [deleteBudgetExpenseDialogOpen, setDeleteBudgetExpenseDialogOpen] =
    useState<BudgetExpense | undefined>(undefined);

  const handleSetBudgetAllocationDialogFinished = (
    allocations: BudgetAllocation[]
  ) => {
    setBudgetAllocations(allocations);
    setSetBudgetAllocationDialogOpen(false);
  };

  const handleDeleteBudgetExpenseDialogFinished = () => {
    deleteBudgetExpense(deleteBudgetExpenseDialogOpen!.id);
    setDeleteBudgetExpenseDialogOpen(undefined);
  };

  const handleAddBudgetExpenseDialogFinished = (expense: BudgetExpense) => {
    addBudgetExpense(expense);
    setAddBudgetExpenseDialogOpen(false);
  };

  return (
    <Page>
      <NavigationBar loading={data.state === "InitialLoading"} />
      <ContentWrapper>
        {setBudgetAllocationDialogOpen && (
          <SetBudgetAllocationDialog
            onFinished={handleSetBudgetAllocationDialogFinished}
            onCancel={() => setSetBudgetAllocationDialogOpen(false)}
          />
        )}
        {addBudgetExpenseDialogOpen && (
          <AddBudgetExpenseDialog
            onFinished={handleAddBudgetExpenseDialogFinished}
            onCancel={() => setAddBudgetExpenseDialogOpen(false)}
          />
        )}
        {deleteBudgetExpenseDialogOpen && (
          <DeleteBudgetExpenseDialog
            expense={deleteBudgetExpenseDialogOpen}
            onFinished={handleDeleteBudgetExpenseDialogFinished}
            onCancel={() => setDeleteBudgetExpenseDialogOpen(undefined)}
          />
        )}
        {data.state === "Loaded" && data.allocations.length === 0 && (
          <div>
            hi you have no budget
            <Button onClick={() => setSetBudgetAllocationDialogOpen(true)}>
              Set Budget
            </Button>
          </div>
        )}
        {data.state === "Loaded" && data.allocations.length > 0 && (
          <>
            <Typography>
              Current budget:{" "}
              {formatCurrency(currentAllocation(data.allocations))}
            </Typography>
            <Typography>
              Total budget:{" "}
              {formatCurrency(totalAllocation(user, data.allocations))}
            </Typography>
            <Typography>
              Total spent: {formatCurrency(totalSpent(data.expenses))}
            </Typography>
            <Typography variant="h6">
              Remaining budget:{" "}
              {formatCurrency(
                remainingBudget(user, data.allocations, data.expenses)
              )}
            </Typography>
            <div>
              <Button onClick={() => setSetBudgetAllocationDialogOpen(true)}>
                Update Budget
              </Button>
              <Button onClick={() => setAddBudgetExpenseDialogOpen(true)}>
                Add expense
              </Button>
            </div>
            <ExpenseTable
              expenses={data.expenses}
              timezone={user.timezone}
              onExpenseDeleted={(expense) =>
                setDeleteBudgetExpenseDialogOpen(expense)
              }
            />
          </>
        )}
      </ContentWrapper>
    </Page>
  );
};
