import type { Printable } from "../types";

import {
  cuboid,
  cylinder,
  cylinderElliptic,
} from "@jscad/modeling/src/primitives";
import { transforms, booleans } from "@jscad/modeling";

const mixerOuterRadius = 37.5 / 2;
const mixerInnerRadiusStart = 23.5 / 2;
const mixerInnerRadiusEnd = 21.5 / 2;
const insertionInnerWallRadius = 16.5 / 2;

const driveHeight = 18;
const driveSize = 11.5;

const shoulderHeight = 15;

const cutoutRadius = 7.5 / 2;
const cutoutHeight = 4.5;

const screwHoleSetback = 10;

const bowlScrewRadius = 6 / 2;

const insertionDepth = 20;

const totalPartHeight = shoulderHeight + insertionDepth;

const plateDriveRadius = 60 / 2;
const plateDriveHeight = 8;

const shoulderBulk = transforms.translateZ(
  shoulderHeight / 2,
  cylinder({ radius: mixerOuterRadius, height: shoulderHeight })
);
const insertBulk = transforms.translateZ(
  shoulderHeight + insertionDepth / 2,
  cylinderElliptic({
    startRadius: [mixerInnerRadiusStart, mixerInnerRadiusStart],
    endRadius: [mixerInnerRadiusEnd, mixerInnerRadiusEnd],
    height: insertionDepth,
  })
);

const innerShaftCutout = transforms.translateZ(
  totalPartHeight / 2,
  cylinder({
    radius: insertionInnerWallRadius,
    height: totalPartHeight,
  })
);

const containingCylinder = transforms.translateZ(
  totalPartHeight / 2,
  cylinder({
    radius: mixerOuterRadius,
    height: totalPartHeight,
  })
);

const cutoutFiller = transforms.translateX(
  mixerOuterRadius,
  transforms.translateZ(
    cutoutHeight / 2 + shoulderHeight,
    cylinder({
      radius: cutoutRadius,
      height: cutoutHeight,
    })
  )
);

const screwHole = transforms.translate(
  [mixerInnerRadiusStart, 0, shoulderHeight + screwHoleSetback],
  transforms.rotateY(
    Math.PI / 2,
    cylinderElliptic({
      startRadius: [0.1, 0.1],
      endRadius: [3, 3],
      height: 4,
    })
  )
);

const insert = booleans.intersect(
  containingCylinder,
  booleans.subtract(
    booleans.union([shoulderBulk, insertBulk, cutoutFiller]),
    innerShaftCutout,
    screwHole
  )
);

const bowlMountingPlate = transforms.translateZ(
  plateDriveHeight / 2,
  cylinder({
    radius: plateDriveRadius,
    height: plateDriveHeight,
  })
);
const leftBowlScrew = transforms.translate(
  [(mixerOuterRadius + plateDriveRadius) / 2, 0, plateDriveHeight / 2],
  cylinder({
    radius: bowlScrewRadius,
    height: plateDriveHeight,
  })
);
const rightBowlScrew = transforms.translate(
  [-(mixerOuterRadius + plateDriveRadius) / 2, 0, plateDriveHeight / 2],
  cylinder({
    radius: bowlScrewRadius,
    height: plateDriveHeight,
  })
);
const shaftCylinder = transforms.translateZ(
  totalPartHeight / 2 + plateDriveHeight,
  cylinder({
    radius: insertionInnerWallRadius - 0.25,
    height: totalPartHeight,
  })
);
const shaftDrive = transforms.translateZ(
  totalPartHeight + plateDriveHeight + driveHeight / 2,
  cuboid({ size: [driveSize, driveSize, driveHeight] })
);
const shaft = transforms.translateZ(
  -20,
  booleans.subtract(
    booleans.union(bowlMountingPlate, shaftCylinder, shaftDrive),
    leftBowlScrew,
    rightBowlScrew
  )
);

export const candyPanner: Printable = {
  name: "Candy Panner",
  slug: "candy-panner",
  description: "Candy Panner Attachment for KitchenAid Mixer",
  solids: [insert, shaft],
};
