import { Page } from "../../components/layout/Page";
import { AuthPageLayout } from "../../components/layout/AuthPageLayout";

import { SignInForm } from "./SignInForm";

export const SignIn: React.FC = () => {
  const title = "Sign In";

  return (
    <Page title={title}>
      <AuthPageLayout rightPane={() => <SignInForm />} />
    </Page>
  );
};
