import { css } from "@emotion/css";
import { Button } from "@isaaczafuta/ui";
import { Temporal } from "@js-temporal/polyfill";
import { GoX } from "react-icons/go";
import { BudgetExpense } from "../../../api";
import { formatCurrency } from "../../../utils/format-currency";

interface ExpenseTableProps {
  expenses: BudgetExpense[];
  timezone: string;
  onExpenseDeleted(reservation: BudgetExpense): void;
}

const formatTimestamp = (timestamp: number, timezone: string) => {
  const instant = Temporal.Instant.fromEpochSeconds(timestamp);
  const datetime = instant.toZonedDateTimeISO(timezone);
  return datetime.toPlainDateTime().toLocaleString();
};

export const ExpenseTable: React.FC<ExpenseTableProps> = ({
  expenses,
  timezone,
  onExpenseDeleted,
}) => {
  const sortedExpenses = expenses.sort((a, b) => b.timestamp - a.timestamp);

  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <table
        className={css`
          flex: 1;
        `}
      >
        <thead>
          <tr>
            <td width="25%">Expense</td>
            <td width="25%">Date</td>
            <td width="25%">Amount</td>
            <td width="25%"></td>
          </tr>
        </thead>
        <tbody>
          {sortedExpenses.map((expenses) => (
            <tr key={expenses.id.toString()}>
              <td>{expenses.comment}</td>
              <td>{formatTimestamp(expenses.timestamp, timezone)}</td>
              <td>{formatCurrency(expenses.amountCents)}</td>
              <td>
                <Button
                  fullWidth
                  onClick={() => onExpenseDeleted(expenses)}
                  icon={<GoX />}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
