import { ContentWrapper, Typography } from "@isaaczafuta/ui";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";

export const PydhtPage: React.FC = () => {
  return (
    <Page>
      <NavigationBar gutterBottom={false} />

      <ContentWrapper>
        <Typography variant="h4" gutterBottom>
          pydht
        </Typography>
        <p>
          <strong>pydht</strong> is a simple distributed hash table
          implementation in python.
        </p>
        <p>
          A distributed hash table can be used much like a regular dictionary,
          but the keys and values are distributed across multiple nodes. Nodes
          in the DHT network will automatically discover additional nodes as
          needed, and will adapt when nodes go offline. Key-values will be
          distributed according to a set of rules, and automatically
          re-distributed among the nodes as the network of nodes evolves.
        </p>
        <hr />
        <p>
          <strong>Example usage:</strong>
        </p>
        <blockquote>
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>from pydht import
            DHT
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"} </span>host1, port1 =
            'localhost', 3000
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>dht1 = DHT(host1,
            port1)
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>host2, port2 =
            'localhost', 3001
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>dht2 = DHT(host2,
            port2)
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>dht1["my_key"] =
            [u"My", u"json-serializable", u"Object"]
          </code>
          <br />
          <code className="has-text-dark">
            <span className="is-unselectable">{">>>"}</span>print dht1["my_key"]
          </code>
          <br />
          <code>
            <span className="is-unselectable">
              [u"My", u"json-serializable", u"Object"]
            </span>
          </code>
        </blockquote>
      </ContentWrapper>
    </Page>
  );
};
