import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import { delay } from "../../utils/delay";
import * as api from "../../api";
import { Page } from "../../components/layout/Page";
import { AuthenticatedUserContext } from "../../context/authenticated-user-context";

export const SignOut: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    const signOut = async () => {
      await Promise.any([api.signOutUser(), delay(2000)]);
      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      setUser(undefined);
      navigate("/");
    };

    signOut();
  }, [navigate, setUser]);

  return <Page title="Signing you out..." />;
};
