import { ContentWrapper, Typography } from "@isaaczafuta/ui";
import { Link } from "react-router-dom";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";

interface EntryProps {
  date: string;
  title: string;
  blurb: string;
  link: string;
}

const Entry: React.FC<EntryProps> = ({ date, title, blurb, link }) => {
  return (
    <div>
      <Typography as="span">
        <Link to={link}>{title}</Link>
      </Typography>{" "}
      <Typography as="span">({date})</Typography>{" "}
      <Typography as="span">{blurb}</Typography>
    </div>
  );
};

export const Blog: React.FC = () => (
  <Page>
    <NavigationBar gutterBottom={false} />

    <ContentWrapper>
      <Typography variant="h4" gutterBottom>
        Blog
      </Typography>
      <Entry
        date="2022-07-29"
        title="Fornuftig"
        blurb="Add smarthome-y capabilities to the IKEA Fornuftig air purifier"
        link="/blog/fornuftig"
      />
    </ContentWrapper>
  </Page>
);
