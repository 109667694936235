import { Page } from "../../components/layout/Page";
import { AuthPageLayout } from "../../components/layout/AuthPageLayout";

import { RegisterForm } from "./RegisterForm";

export const Register: React.FC = () => {
  const title = "Register";

  return (
    <Page title={title}>
      <AuthPageLayout rightPane={() => <RegisterForm />} />
    </Page>
  );
};
