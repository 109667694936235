import { Dialog, TextInput, Typography } from "@isaaczafuta/ui";
import { useState } from "react";
import { PickleballCredentials, setPickleballCredentials } from "../../../api";
import { useStateFromDependency } from "../../../hooks/use-state-from-dependency";

interface SetCredentialsDialogProps {
  defaultUsername?: string;
  onFinished(credentials: PickleballCredentials): void;
  onCancel(): void;
}

export const SetCredentialsDialog: React.FC<SetCredentialsDialogProps> = ({
  defaultUsername,
  onCancel,
  onFinished,
}) => {
  const [username, setUsername] = useStateFromDependency(defaultUsername || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await setPickleballCredentials(username, password);
    switch (response.status) {
      case "success":
        onFinished(response.data.credentials);
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Set Credentials"
      cancelEnabled={!submitting}
      confirmEnabled={
        !submitting && password === confirmPassword && password !== ""
      }
      error={error}
    >
      <Typography gutterBottom>
        Set your pickleball credentials, which we use to sign in and make the
        reservations.
      </Typography>
      <br />
      <TextInput
        disabled={submitting}
        label="Username"
        value={username}
        onChange={(value) => setUsername(value)}
        gutterBottom
      />
      <TextInput
        disabled={submitting}
        label="Password"
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
        gutterBottom
      />
      <TextInput
        disabled={submitting}
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(value) => setConfirmPassword(value)}
        gutterBottom
      />
    </Dialog>
  );
};
