import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { css } from "@emotion/css";

import { Button, TextInput, Typography, useTheme } from "@isaaczafuta/ui";

import * as api from "../../api";
import { useMediaQuery } from "../../hooks/use-media-query";
import { AuthenticatedUserContext } from "../../context/authenticated-user-context";

export const RegisterFormWrapper: React.FC = ({ children }) => {
  const { spacing } = useTheme();

  const showSidebar = useMediaQuery(`(min-width: 900px)`);

  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      {showSidebar && (
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            margin: ${spacing.large};
            position: absolute;
            right: 0;
          `}
        ></div>
      )}
      <div
        className={css`
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          className={css`
            margin: 0 ${spacing.large};
            width: 100%;
            max-width: 400px;
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const RegisterForm: React.FC = () => {
  const { colors } = useTheme();

  const navigate = useNavigate();
  const { setUser } = useContext(AuthenticatedUserContext);

  const [searchParams] = useSearchParams();

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState<string>();
  const [errorField, setErrorField] = useState<"name" | "email" | "password">();

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (name.length === 0) {
      setError("Email is required");
      setErrorField("email");
      return;
    }
    if (email.length === 0) {
      setError("Email is required");
      setErrorField("email");
      return;
    }
    if (password.length === 0) {
      setError("Password is required");
      setErrorField("password");
      return;
    }

    setError(undefined);
    setErrorField(undefined);
    setSubmitting(true);

    const response = await api.registerUser(name, email, password);
    if (response.status === "success") {
      setUser(response.data.user);
      navigate(searchParams.get("next") || "/app");
    } else {
      setError(response.message);
      setSubmitting(false);
    }
  };

  return (
    <RegisterFormWrapper>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" fontWeight={700} gutterBottom>
          Register
        </Typography>
        {error && !errorField && (
          <Typography gutterBottom color={colors.error.main}>
            {error}
          </Typography>
        )}
        <TextInput
          ref={nameInputRef}
          label="Name"
          type="text"
          value={name}
          onChange={setName}
          disabled={submitting}
          gutterBottom
          errorMessage={errorField === "name" ? error : undefined}
        />
        <TextInput
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          disabled={submitting}
          gutterBottom
          errorMessage={errorField === "email" ? error : undefined}
        />
        <TextInput
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
          disabled={submitting}
          gutterBottom
          errorMessage={errorField === "password" ? error : undefined}
        />
        <div
          className={css`
            display: flex;
          `}
        >
          <Button
            gutterBottom
            fullWidth
            disabled={submitting}
            onClick={handleSubmit}
            className={css`
              min-width: 200px;
            `}
          >
            Register
          </Button>
        </div>
        <Typography>
          Have an account? <Link to="/signin">Sign In</Link>
        </Typography>
      </form>
    </RegisterFormWrapper>
  );
};
