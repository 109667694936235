import type { Printable } from "../types";
import {
  primitives,
  transforms,
  extrusions,
  booleans,
  utils,
} from "@jscad/modeling";
import { roundedPancake } from "../shapes";

const segments = 40;
const widthThatGoesOnWallOnEachSide = 4;
const heightThatGoesOnWallOnBottom = 8;

const basePlate = (x: number, y: number, z: number, roundRadius: number) => {
  const profile = primitives.roundedRectangle({
    size: [x, y],
    roundRadius,
  });
  return extrusions.extrudeLinear({ height: z }, profile);
};

const holder = (
  x: number,
  y: number,
  baseplateZ: number,
  flangeSize: number,
  roundRadius: number,
  screwHoleDiameter: number
) => {
  const boardCutoutZ = 12;
  const boardCutoutRadius = 80;
  const lipY = 13;
  const lipZ = 17;
  const lipCutoutDelta = 5;

  const base = basePlate(x, y, baseplateZ, roundRadius);
  const flange = basePlate(x + flangeSize * 2, y, baseplateZ / 2, roundRadius);
  const lip = (() => {
    const profile = primitives.roundedRectangle({
      size: [x, lipY],
      roundRadius,
    });
    const lip = extrusions.extrudeLinear(
      { height: baseplateZ + lipZ },
      profile
    );
    return transforms.translate([0, -y / 2 + lipY / 2, 1], lip);
  })();

  const boardCutout = (() => {
    const pancake = transforms.translate(
      [0, boardCutoutRadius],
      roundedPancake(boardCutoutRadius, boardCutoutZ, segments)
    );
    return transforms.translate(
      [0, -y / 2 + lipCutoutDelta, boardCutoutZ / 2 + baseplateZ],
      pancake
    );
  })();

  const holderWithoutScrewHole = booleans.subtract(
    booleans.union(base, flange, lip),
    boardCutout
  );

  if (screwHoleDiameter > 0) {
    const screwHole = primitives.cylinder({
      radius: screwHoleDiameter / 2,
      height: y,
    });
    const alignedScrewHole1 = transforms.translate(
      [-x / 4, 0, baseplateZ / 2],
      transforms.rotateX(utils.degToRad(90), screwHole)
    );
    const alignedScrewHole2 = transforms.translate(
      [x / 4, 0, baseplateZ / 2],
      transforms.rotateX(utils.degToRad(90), screwHole)
    );

    return booleans.subtract(
      holderWithoutScrewHole,
      alignedScrewHole1,
      alignedScrewHole2
    );
  } else {
    const nailHoleDiameter = 2;
    const nailHeadHoleDiameter = 4;

    const nailHole1 = primitives.cylinder({
      radius: nailHoleDiameter / 2,
      height: baseplateZ,
      center: [-x / 4, y / 4, baseplateZ / 2],
    });
    const nailHeadHole1 = primitives.cylinder({
      radius: nailHeadHoleDiameter / 2,
      height: baseplateZ / 2,
      center: [-x / 4, y / 4, (3 * baseplateZ) / 4],
    });
    const nailHole2 = primitives.cylinder({
      radius: nailHoleDiameter / 2,
      height: baseplateZ,
      center: [x / 4, y / 4, baseplateZ / 2],
    });
    const nailHeadHole2 = primitives.cylinder({
      radius: nailHeadHoleDiameter / 2,
      height: baseplateZ / 2,
      center: [x / 4, y / 4, (3 * baseplateZ) / 4],
    });
    return booleans.subtract(
      holderWithoutScrewHole,
      nailHole1,
      nailHeadHole1,
      nailHole2,
      nailHeadHole2
    );
  }
};

const slidingMount = (
  holderX: number,
  holderY: number,
  baseplateZ: number,
  flangeSize: number,
  roundRadius: number,
  screwHoleDiameter: number
) => {
  const extraBaseplateZ = 4;
  const cutoutSlop = 1;

  const hull = basePlate(
    holderX + 2 * flangeSize + 2 * widthThatGoesOnWallOnEachSide,
    holderY + heightThatGoesOnWallOnBottom,
    baseplateZ + extraBaseplateZ,
    roundRadius
  );
  const alignedHull = transforms.translateY(
    heightThatGoesOnWallOnBottom / 2,
    hull
  );

  const holderCutout = transforms.translateZ(
    extraBaseplateZ,
    holder(
      holderX + cutoutSlop,
      holderY + cutoutSlop,
      baseplateZ + cutoutSlop,
      flangeSize,
      roundRadius,
      0
    )
  );
  const innerRoundKnockout = primitives.cuboid({
    size: [holderX + cutoutSlop, roundRadius, baseplateZ],
    center: [
      0,
      -holderY / 2 + roundRadius / 2,
      baseplateZ / 2 + extraBaseplateZ,
    ],
  });
  const outerRoundKnockout = primitives.cuboid({
    size: [
      holderX + cutoutSlop + 2 * flangeSize,
      roundRadius,
      baseplateZ / 2 + cutoutSlop / 2,
    ],
    center: [
      0,
      -holderY / 2 + roundRadius / 2,
      baseplateZ / 4 + extraBaseplateZ + cutoutSlop / 4,
    ],
  });

  const screwHole = primitives.cylinder({
    radius: screwHoleDiameter / 2,
    height: holderY + heightThatGoesOnWallOnBottom,
  });
  const alignedScrewHole1 = transforms.translate(
    [
      -holderX / 4,
      heightThatGoesOnWallOnBottom / 2,
      baseplateZ / 2 + extraBaseplateZ,
    ],
    transforms.rotateX(utils.degToRad(90), screwHole)
  );
  const alignedScrewHole2 = transforms.translate(
    [
      holderX / 4,
      heightThatGoesOnWallOnBottom / 2,
      baseplateZ / 2 + extraBaseplateZ,
    ],
    transforms.rotateX(utils.degToRad(90), screwHole)
  );

  const nailHoleDiameter = 2;
  const nailHeadHoleDiameter = 4;

  const nailHole1 = primitives.cylinder({
    radius: nailHoleDiameter / 2,
    height: extraBaseplateZ,
    center: [-holderX / 4, 0, extraBaseplateZ / 2],
  });
  const nailHeadHole1 = primitives.cylinder({
    radius: nailHeadHoleDiameter / 2,
    height: extraBaseplateZ / 2,
    center: [-holderX / 4, 0, (3 * extraBaseplateZ) / 4],
  });

  const nailHole2 = primitives.cylinder({
    radius: nailHoleDiameter / 2,
    height: extraBaseplateZ,
    center: [holderX / 4, 0, extraBaseplateZ / 2],
  });
  const nailHeadHole2 = primitives.cylinder({
    radius: nailHeadHoleDiameter / 2,
    height: extraBaseplateZ / 2,
    center: [holderX / 4, 0, (3 * extraBaseplateZ) / 4],
  });

  return booleans.union(
    booleans.subtract(
      alignedHull,
      holderCutout,
      innerRoundKnockout,
      outerRoundKnockout,
      alignedScrewHole1,
      alignedScrewHole2,
      nailHole1,
      nailHeadHole1,
      nailHole2,
      nailHeadHole2
    )
  );
};

const main = () => {
  const topHolderWidth = 45;
  const bottomHolderWidth = 45;
  const topHolderHeight = 18;
  const bottomHolderHeight = 27;

  const roundRadius = 3;
  const flangeSize = 5;
  const baseplateZ = 10;

  const screwHoleDiameter = 5;

  const topHolder = holder(
    topHolderWidth,
    topHolderHeight,
    baseplateZ,
    flangeSize,
    roundRadius,
    screwHoleDiameter
  );
  const topHolderSlidingMount = slidingMount(
    topHolderWidth,
    topHolderHeight,
    baseplateZ,
    flangeSize,
    roundRadius,
    screwHoleDiameter + 1
  );
  const bottomHolder = holder(
    bottomHolderWidth,
    bottomHolderHeight,
    baseplateZ,
    0,
    roundRadius,
    0
  );
  return [
    transforms.rotateX(
      utils.degToRad(90),
      transforms.translateY(topHolderHeight / 2, topHolder)
    ),
    transforms.rotateX(
      utils.degToRad(90),
      transforms.translateX(
        topHolderWidth / 2 + bottomHolderWidth / 2 + flangeSize + 10,
        bottomHolder
      )
    ),
    transforms.rotateX(
      utils.degToRad(-90),
      transforms.translateX(
        -topHolderWidth - topHolderWidth - widthThatGoesOnWallOnEachSide - 10,
        topHolderSlidingMount
      )
    ),
  ];
};

export const skateboardHolder: Printable = {
  name: "Skateboard Holder",
  slug: "skateboard-holder",
  description: "Mount Skateboards on the wall, bottom side out.",
  solids: main(),
};
