import { css } from "@emotion/css";
import { Typography, useTheme } from "@isaaczafuta/ui";
import { Link } from "react-router-dom";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";

const MainContainer: React.FC = ({ children }) => (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow-y: auto;
    `}
  >
    {children}
  </div>
);

const CenteredContent: React.FC = ({ children }) => (
  <div
    className={css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <div>{children}</div>
  </div>
);

const Items: React.FC = ({ children }) => {
  const { spacing } = useTheme();
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing.large};
      `}
    >
      {children}
    </div>
  );
};

interface ItemProps {
  label: string;
  color: string;
  link: string;
  type: "internal" | "external";
}

const Item: React.FC<ItemProps> = ({ label, color, link, type }) => {
  const { colors, spacing } = useTheme();

  const linkContents = (
    <div
      className={css`
        display: flex;
        gap: ${spacing.medium};
      `}
    >
      <div
        className={css`
          width: 6px;
          background-color: ${color};
        `}
      />
      <div>
        <Typography color={colors.background.contrastText}>{label}</Typography>
      </div>
    </div>
  );
  if (type === "external") {
    return <a href={link}>{linkContents}</a>;
  } else {
    return <Link to={link}>{linkContents}</Link>;
  }
};

export const Home: React.FC = () => {
  const { colors } = useTheme();
  return (
    <Page>
      <MainContainer>
        <NavigationBar gutterBottom={false} />
        <CenteredContent>
          <Items>
            {/* <Item
              label="Code"
              color={colors.primary.main}
              link="/code"
              type="internal"
            />
            <Item
              label="3D Printing"
              color={colors.secondary.main}
              link="/printing"
              type="internal"
            /> */}
            <Item
              label="Tools"
              color={colors.warning.main}
              link="/tools"
              type="internal"
            />
            {/* <Item
              label="Resume"
              color={colors.danger.main}
              link="/resume.pdf"
              type="external"
            /> */}
            {/* <Item
              label="Blog"
              color={colors.danger.main}
              link="/blog"
              type="internal"
            /> */}
          </Items>
        </CenteredContent>
      </MainContainer>
    </Page>
  );
};
