import { TextInput } from "@isaaczafuta/ui";
import React, { FocusEventHandler, forwardRef, MouseEventHandler } from "react";
import { formatCurrency } from "../../../utils/format-currency";

interface Props {
  value: number;
  label?: string;
  icon?: React.ReactNode;
  onIconClick?: MouseEventHandler<HTMLDivElement>;
  gutterBottom?: boolean;
  errorMessage?: string;
  onChange?: (value: number, e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface HTMLProps {
  autocomplete?: HTMLInputElement["autocomplete"];
  disabled?: HTMLInputElement["disabled"];
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

interface Focusable {
  focus: () => void;
}

export const CurrencyInput = forwardRef<Focusable, Props & HTMLProps>(
  ({ value, onChange, ...rest }, ref) => {
    const handleValueChanged = (
      newValue: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (!onChange) {
        return;
      }

      newValue = newValue.replace(/,/g, "");
      newValue = newValue.replace(/\./g, "");
      newValue = newValue.replace(/\$/g, "");

      if (!/^[0-9]*$/.test(newValue)) {
        return;
      }

      if (newValue.length === 0) {
        newValue = "0" + newValue;
      }

      const newCandidateInt = parseInt(newValue, 10);
      if (!isNaN(newCandidateInt)) {
        onChange(newCandidateInt, e);
      }
    };

    return (
      <TextInput
        value={formatCurrency(value)}
        onChange={handleValueChanged}
        {...rest}
        ref={ref}
      />
    );
  }
);
