import { Navigate } from "react-router";
import { UserRole } from "../api";

import { useAuthenticatedUser } from "../hooks/use-authenticated-user";

interface Props {
  role: UserRole;
  path?: string;
}

export const RequireAuth: React.FC<Props> = ({ role, children, path }) => {
  const user = useAuthenticatedUser();
  const authorized = user && user.roles.includes(role);

  const url = path ? `/signin?next=${path}` : "/signin";

  return authorized ? <>{children}</> : <Navigate to={url} replace />;
};
