import { useEffect, useState, Dispatch, SetStateAction } from "react";

import { usePrevious } from "./use-previous";

type UseStateFromDependency = {
  <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];
  <S>(initialState: S | undefined): [
    S,
    Dispatch<SetStateAction<S | undefined>>
  ];
};

export const useStateFromDependency: UseStateFromDependency = <S>(
  dependency: S
) => {
  const [state, setState] = useState<S | undefined>(dependency);

  const previous = usePrevious(dependency);

  useEffect(() => {
    if (dependency !== previous) {
      setState(dependency);
    }
  }, [dependency, previous]);

  return [state, setState];
};
