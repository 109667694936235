import { Link } from "react-router-dom";
import { ContentWrapper, Typography } from "@isaaczafuta/ui";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";

export const NotFound: React.FC = () => {
  return (
    <Page>
      <NavigationBar gutterBottom />
      <ContentWrapper small>
        <Typography variant="h5" gutterBottom>
          404, Page Not Found
        </Typography>
        <Typography gutterBottom>Oh no, couldn't find that page!</Typography>
        <Typography>
          <Link to="/">Return Home</Link>
        </Typography>
      </ContentWrapper>
    </Page>
  );
};
