import { ContentWrapper, Typography } from "@isaaczafuta/ui";
import { Link } from "react-router-dom";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";

export const Tools: React.FC = () => {
  return (
    <Page>
      <NavigationBar />
      <ContentWrapper>
        <Typography variant="h4">Tools</Typography>
        <ul>
          <li>
            <Link to="/pickle-grabber">Pickleball Court Grabber</Link>
          </li>
          <li>
            <Link to="/budget">Budget</Link>
          </li>
        </ul>
      </ContentWrapper>
    </Page>
  );
};
