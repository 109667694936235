import * as api from "../../api";

import { Page } from "../../components/layout/Page";
import {
  Button,
  ContentWrapper,
  TextInput,
  Typography,
  useTheme,
} from "@isaaczafuta/ui";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { NavigationBar } from "../../components/layout/NavigationBar";

export const ChangePassword: React.FC = () => {
  const title = "Change Password";

  return (
    <Page title={title}>
      <NavigationBar gutterBottom />
      <ContentWrapper small>
        <ChangePasswordForm />
      </ContentWrapper>
    </Page>
  );
};

export const ChangePasswordForm: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState<string>();
  const [errorField, setErrorField] = useState<"oldPassword" | "newPassword">();

  useEffect(() => {
    passwordInputRef.current?.focus();
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (oldPassword.length === 0) {
      setError("Current Password is required");
      setErrorField("oldPassword");
      return;
    }

    if (newPassword.length === 0) {
      setError("New Password is required");
      setErrorField("newPassword");
      return;
    }

    setError(undefined);
    setErrorField(undefined);
    setSubmitting(true);

    const response = await api.changePassword(oldPassword, newPassword);
    if (response.status === "success") {
      navigate("/app");
    } else {
      setError(response.message);
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" fontWeight={700} gutterBottom>
        Change Password
      </Typography>
      {error && !errorField && (
        <Typography gutterBottom color={colors.error.main}>
          {error}
        </Typography>
      )}
      <TextInput
        ref={passwordInputRef}
        label="Current Password"
        type="password"
        value={oldPassword}
        onChange={setOldPassword}
        disabled={submitting}
        gutterBottom
        errorMessage={errorField === "oldPassword" ? error : undefined}
      />
      <TextInput
        label="New Password"
        type="password"
        value={newPassword}
        onChange={setNewPassword}
        disabled={submitting}
        gutterBottom
        errorMessage={errorField === "newPassword" ? error : undefined}
      />
      <div
        className={css`
          display: flex;
        `}
      >
        <Button
          gutterBottom
          fullWidth
          disabled={submitting}
          onClick={handleSubmit}
          className={css`
            min-width: 200px;
          `}
        >
          Change Password
        </Button>
      </div>
    </form>
  );
};
