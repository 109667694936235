import { css } from "@emotion/css";
import { useTheme } from "@isaaczafuta/ui";

const MainContainer: React.FC = ({ children }) => (
  <div
    className={css`
      display: flex;
      height: 100vh;
      overflow-y: auto;
    `}
  >
    {children}
  </div>
);

const MainContent: React.FC = ({ children }) => {
  const { colors } = useTheme();
  return (
    <div
      className={css`
        flex: 1;
        background-color: ${colors.white.main};
        display: flex;
        overflow-y: auto;
      `}
    >
      {children}
    </div>
  );
};

interface Props {
  rightPane: () => React.ReactNode;
}

export const AuthPageLayout: React.FC<Props> = ({ rightPane }) => {
  return (
    <MainContainer>
      <MainContent>{rightPane()}</MainContent>
    </MainContainer>
  );
};
