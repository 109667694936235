import { Dialog, TextInput, Typography } from "@isaaczafuta/ui";
import { useEffect, useRef, useState } from "react";
import { BudgetAllocation, setBudgetAllocation } from "../../../api";
import { CurrencyInput } from "../components/CurrencyInput";

interface SetBudgetAllocationDialogProps {
  onFinished(allocations: BudgetAllocation[]): void;
  onCancel(): void;
}

export const SetBudgetAllocationDialog: React.FC<
  SetBudgetAllocationDialogProps
> = ({ onCancel, onFinished }) => {
  const [budgetAllocationCents, setBudgetAllocationCents] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await setBudgetAllocation(budgetAllocationCents);
    switch (response.status) {
      case "success":
        onFinished(response.data.allocations);
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  const budgetInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    budgetInputRef.current?.focus();
  }, []);

  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Set Budget Allocation"
      cancelEnabled={!submitting}
      confirmEnabled={!submitting}
      error={error}
    >
      <Typography gutterBottom>
        Set your budget allocation. This is how much you'll be allowed to spend
        per day.
      </Typography>
      <br />
      <CurrencyInput
        ref={budgetInputRef}
        disabled={submitting}
        label="Budget Allocation"
        value={budgetAllocationCents}
        onChange={(value) => setBudgetAllocationCents(value)}
        gutterBottom
      />
    </Dialog>
  );
};
