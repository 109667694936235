import { Link } from "react-router-dom";

import { css, keyframes } from "@emotion/css";

import { useAuthenticatedUser } from "../../hooks/use-authenticated-user";
import { Typography, useTheme } from "@isaaczafuta/ui";

interface NavigationBarLinkProps {
  label: string;
  to: string;
}

const NavigationBarLink: React.FC<NavigationBarLinkProps> = ({
  label,
  to: location,
}) => {
  const { colors } = useTheme();

  return (
    <Link
      className={css`
        color: ${colors.primary.contrastText};
      `}
      to={location}
    >
      <Typography as="span">{label}</Typography>
    </Link>
  );
};

interface Props {
  loading?: boolean;
  gutterBottom?: boolean;
}

export const NavigationBar: React.FC<Props> = ({
  gutterBottom = true,
  loading = false,
}) => {
  const { colors, spacing, shadows } = useTheme();
  const user = useAuthenticatedUser();

  const rainbowSlide = keyframes`
  0% {
    background-position: 100%}
  100% {
    background-position: 0}`;

  return (
    <header
      className={css`
        position: relative;
        color: ${colors.primary.contrastText};
        background-color: ${colors.primary.main};
        box-shadow: ${shadows.normal};
        margin-bottom: ${gutterBottom ? spacing.large : 0};
      `}
    >
      {loading && (
        <div
          className={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: ${spacing.small};
            background-color: red;
            background: linear-gradient(
              90deg,
              #ffd33d,
              #ea4aaa 17%,
              #b34bff 34%,
              #01feff 51%,
              #ffd33d 68%,
              #ea4aaa 85%,
              #b34bff
            );
            background-size: 300% 100%;
            animation: ${rainbowSlide} 2s linear infinite;
          `}
        />
      )}
      <div
        className={css`
          padding: ${spacing.large};
          font-weight: 500;

          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <NavigationBarLink to="/" label="Isaac Zafuta" />
        <nav
          className={css`
            display: flex;
            gap: ${spacing.large};
          `}
        >
          <div
            className={css`
              display: flex;
              gap: ${spacing.large};
            `}
          >
            <NavigationBarLink to="/" label="Home" />
            {!user && <NavigationBarLink to="/signin" label="Private" />}
            {user && <NavigationBarLink to="/signout" label="Sign Out" />}
          </div>
        </nav>
      </div>
    </header>
  );
};
