import { useEffect } from "react";

interface Props {
  title?: string;
}

export const Page: React.FC<Props> = ({ title, children }) => {
  useEffect(() => {
    const trimmed = (title || "").trim();
    const suffix = "Isaac Zafuta";
    document.title =
      trimmed && trimmed !== suffix ? `${title} · ${suffix}` : suffix;
  }, [title]);

  return <>{children}</>;
};
