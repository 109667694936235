import { ContentWrapper, Typography } from "@isaaczafuta/ui";

import { NavigationBar } from "../../../components/layout/NavigationBar";
import { Page } from "../../../components/layout/Page";

export const Fornuftig: React.FC = () => (
  <Page>
    <NavigationBar gutterBottom={false} />

    <ContentWrapper>
      <Typography variant="h4" gutterBottom>
        Fornuftig
      </Typography>

      <Typography gutterBottom>
        Hacking the IKEA Fornuftig air purifier.
      </Typography>

      <Typography gutterBottom>
        IKEA's Fornuftig is hella cheap. The economics of the filters and stuff
        make sense. It has 3 levels, and the first level is just about
        inaudible. But the two higher fan levels are a bit loud.
      </Typography>

      <Typography>
        It would be really nice if it would turn itself up when the air was
        particularly dirty, and turn itself off when I'm trying to sleep. Using
        a Pi Pico W, I was able to add smart capabilities to the Fornuftig.
      </Typography>
    </ContentWrapper>
  </Page>
);
