import { ContentWrapper, Typography } from "@isaaczafuta/ui";
import React from "react";
import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";
import { Renderer } from "jscad-react";
// @ts-ignore
import serializer from "@jscad/stl-serializer";
import type { Printable } from "./types";

interface Props {
  object: Printable;
}

export const PrintableObject: React.FC<Props> = ({ object }) => {
  const geometry = object.solids;
  const rawData = serializer.serialize({ binary: true }, geometry);
  const blob = new Blob(rawData, { type: "model/stl" });
  const url = URL.createObjectURL(blob);

  return (
    <Page>
      <NavigationBar gutterBottom />
      <ContentWrapper small>
        <div>
          <Renderer
            solids={object.solids}
            options={{ gridOptions: { show: false } }}
          />
          <Typography>
            <a href={url} download={`${object.slug}.stl`}>
              Downloaddddd
            </a>
          </Typography>
        </div>
      </ContentWrapper>
    </Page>
  );
};
