import * as JSONBigint from "../utils/json-bigint";

interface APIErrorResponse {
  status: "error";
  message: string;
  code?: number;
}

interface APISuccessResponse<T> {
  status: "success";
  data: T;
}

type APIResponse<T = unknown> = APIErrorResponse | APISuccessResponse<T>;

const unknownErrorMessage = "An unknown error occurred";

const makeRequest = async <T>(
  method: string,
  url: string,
  args: any
): Promise<APIResponse<T>> => {
  const argsInUrl = ["GET", "HEAD", "DELETE"].includes(method);

  const modifiedUrl =
    argsInUrl && Object.keys(args).length
      ? url + "?" + new URLSearchParams(args)
      : url;

  try {
    const response = await fetch(modifiedUrl, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: argsInUrl ? undefined : JSONBigint.stringify(args),
    });
    if (response.status === 200) {
      const json = await response.text();
      const data = JSONBigint.parse(json);
      return { status: "success", data: data };
    } else {
      try {
        const json = await response.text();
        const data = JSONBigint.parse(json);
        return {
          status: "error",
          message: data.message || unknownErrorMessage,
          code: response.status,
        };
      } catch (e) {
        return {
          status: "error",
          message: unknownErrorMessage,
          code: response.status,
        };
      }
    }
  } catch (e) {
    const message = e instanceof Error ? e.message : unknownErrorMessage;
    return {
      status: "error",
      message,
    };
  }
};

const get = async <T>(
  url: string,
  params: object = {}
): Promise<APIResponse<T>> => {
  return await makeRequest("GET", url, params);
};

const post = async <T>(
  url: string,
  body: object = {}
): Promise<APIResponse<T>> => {
  return await makeRequest("POST", url, body);
};

const delete_ = async <T>(
  url: string,
  params: object = {}
): Promise<APIResponse<T>> => {
  return await makeRequest("DELETE", url, params);
};

export type UserRole = "User" | "Admin";

export interface User {
  id: bigint;
  name: string;
  email: string;
  timezone: string;
  roles: UserRole[];
}

export const getSelf = async (): Promise<APIResponse<{ user: User }>> => {
  return await get("/api/users/self");
};

export const unregisterUser = async () => {
  return await post("/api/users/unregister");
};

export const registerUser = async (
  name: string,
  email: string,
  password: string
): Promise<APIResponse<{ user: User }>> => {
  return await post("/api/users/register", { name, email, password });
};

export const signInUser = async (
  email: string,
  password: string
): Promise<APIResponse<{ user: User }>> => {
  return await post("/api/users/signin", { email, password });
};

export const signOutUser = async (): Promise<APIResponse> => {
  return await post("/api/users/signout");
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<APIResponse> => {
  return await post("/api/users/password", { oldPassword, newPassword });
};

export type DayOfWeek =
  | "Sunday"
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday";

export type PickleReservationDuration = "60 Minutes" | "90 Minutes";

export interface PickleballReservation {
  id: bigint;
  userId: bigint;
  dayOfWeek: DayOfWeek;
  time: string;
  duration: PickleReservationDuration;
}

export const deletePickleballReservation = async (
  reservationId: bigint
): Promise<APIResponse<void>> => {
  return await delete_(
    `/api/pickleball/reservations/${reservationId.toString()}`
  );
};

export const getPickleballReservations = async (): Promise<
  APIResponse<{ reservations: PickleballReservation[] }>
> => {
  return await get("/api/pickleball/reservations");
};

export const addPickleballReservation = async (
  day: DayOfWeek,
  time: string,
  duration: PickleReservationDuration
): Promise<APIResponse<{ reservation: PickleballReservation }>> => {
  return await post("/api/pickleball/reservations", {
    day,
    time,
    duration,
  });
};

export interface PickleballCredentials {
  username: string;
}

export const getPickleballCredentials = async (): Promise<
  APIResponse<{ credentials: PickleballCredentials | null }>
> => {
  return await get("/api/pickleball/credentials");
};

export const setPickleballCredentials = async (
  username: string,
  password: string
): Promise<APIResponse<{ credentials: PickleballCredentials }>> => {
  return await post("/api/pickleball/credentials", {
    username,
    password,
  });
};

export const clearPickleballCredentials = async (): Promise<
  APIResponse<void>
> => {
  return await delete_("/api/pickleball/credentials");
};

export interface BudgetAllocation {
  id: bigint;
  userId: bigint;
  startDateISO: string;
  endDateISO: string;
  amountCents: number;
}

export const getBudgetAllocations = async (): Promise<
  APIResponse<{ allocations: BudgetAllocation[] }>
> => {
  return await get("/api/budget/allocations");
};

export const setBudgetAllocation = async (
  amountCents: number
): Promise<APIResponse<{ allocations: BudgetAllocation[] }>> => {
  return await post("/api/budget/allocations", {
    amountCents,
  });
};

export interface BudgetExpense {
  id: bigint;
  userId: bigint;
  comment: string;
  amountCents: number;
  timestamp: number;
}

export const addBudgetExpense = async (
  comment: string,
  amountCents: number,
  timestamp: number
): Promise<APIResponse<{ expense: BudgetExpense }>> => {
  return await post("/api/budget/expenses", {
    comment,
    amountCents,
    timestamp,
  });
};

export const getBudgetExpenses = async (): Promise<
  APIResponse<{ expenses: BudgetExpense[] }>
> => {
  return await get("/api/budget/expenses");
};

export const deleteBudgetExpense = async (
  expenseId: bigint
): Promise<APIResponse<void>> => {
  return await delete_(`/api/budget/expenses/${expenseId}`);
};
