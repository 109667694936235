import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "inter-ui/inter.css";
import "./index.css";

import { Baseline, ThemeProvider } from "@isaaczafuta/ui";
import {
  Blog,
  Budget,
  Code,
  Fornuftig,
  Home,
  NotFound,
  PickleGrabber,
  Printing,
  printableObjects,
  SignIn,
  SignOut,
  ChangePassword,
  PrintableObject,
  Tools,
  Register,
} from "./routes";
import { AuthenticatedUserProvider } from "./context/authenticated-user-context";
import { PydhtPage } from "./routes/Code/pydht";
import { RequireAuth } from "./utils/require-authentication";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Baseline />
      <AuthenticatedUserProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/fornuftig" element={<Fornuftig />} />
            <Route
              path="/budget"
              element={
                <RequireAuth path="/budget" role="User">
                  <Budget />
                </RequireAuth>
              }
            />
            <Route path="/code" element={<Code />} />
            <Route path="/code/pydht" element={<PydhtPage />} />
            <Route
              path="/pickle-grabber"
              element={
                <RequireAuth path="/pickle-grabber" role="User">
                  <PickleGrabber />
                </RequireAuth>
              }
            />
            <Route path="/printing" element={<Printing />} />
            {printableObjects.map((printableObject) => (
              <Route
                key={printableObject.slug}
                path={`/printing/${printableObject.slug}`}
                element={<PrintableObject object={printableObject} />}
              />
            ))}
            <Route path="/tools" element={<Tools />} />
            <Route path="/register" element={<Register />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signout" element={<SignOut />} />
            <Route
              path="/account/change-password"
              element={
                <RequireAuth path="/account/change-password" role="User">
                  <ChangePassword />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedUserProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
