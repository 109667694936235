import { createContext, useEffect, useState } from "react";

import * as api from "../api";

import type { User } from "../api";

interface AuthenticatedUserContextType {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
}

export const AuthenticatedUserContext =
  createContext<AuthenticatedUserContextType>({
    user: undefined,
    setUser: (_user: User | undefined) => {},
  });

export const AuthenticatedUserProvider: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const getSelf = async () => {
      const response = await api.getSelf();
      if (response.status === "success") {
        setUser(response.data.user);
      }
      setLoaded(true);
    };
    getSelf();
  }, [setUser]);

  if (!loaded) {
    return <div />;
  }

  return (
    <AuthenticatedUserContext.Provider
      value={{ user, setUser: (u: User | undefined) => setUser(u) }}
    >
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
