import { useEffect, useState } from "react";
import {
  BudgetAllocation,
  BudgetExpense,
  getBudgetAllocations,
  getBudgetExpenses,
} from "../../../api";

interface InitialLoad {
  state: "InitialLoading";
}

interface InitialLoadError {
  state: "InitialLoadError";
  error: string;
}

interface Loaded {
  state: "Loaded";
  allocations: BudgetAllocation[];
  expenses: BudgetExpense[];
}

type BudgetData = InitialLoad | InitialLoadError | Loaded;

// Can probably make this generic...
const getInitialBudgetAllocationsThrowing = async () => {
  const response = await getBudgetAllocations();
  switch (response.status) {
    case "success":
      return response.data;
    case "error":
      throw new Error(response.message);
  }
};

const getInitialBudgetExpensesThrowing = async () => {
  const response = await getBudgetExpenses();
  switch (response.status) {
    case "success":
      return response.data;
    case "error":
      throw new Error(response.message);
  }
};

export const useBudgetData = () => {
  const [data, setData] = useState<BudgetData>({ state: "InitialLoading" });

  useEffect(() => {
    const getInitialAllocations = async () => {
      try {
        const { allocations } = await getInitialBudgetAllocationsThrowing();
        const { expenses } = await getInitialBudgetExpensesThrowing();
        setData({
          state: "Loaded",
          allocations,
          expenses,
        });
      } catch (e) {
        setData({
          state: "InitialLoadError",
          error: (e as Error).message,
        });
      }
    };

    getInitialAllocations();
  }, []);

  const setBudgetAllocations = (allocations: BudgetAllocation[]) => {
    if (data.state === "Loaded") {
      setData({
        state: "Loaded",
        allocations,
        expenses: data.expenses,
      });
    }
  };

  const deleteBudgetExpense = (expenseId: BigInt) => {
    if (data.state === "Loaded") {
      setData({
        state: "Loaded",
        allocations: data.allocations,
        expenses: data.expenses.filter((e) => e.id !== expenseId),
      });
    }
  };

  const addBudgetExpense = (expense: BudgetExpense) => {
    if (data.state === "Loaded") {
      setData({
        state: "Loaded",
        allocations: data.allocations,
        expenses: [...data.expenses, expense],
      });
    }
  };

  return { data, addBudgetExpense, setBudgetAllocations, deleteBudgetExpense };
};
