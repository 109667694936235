import { css } from "@emotion/css";
import {
  Button,
  ContentWrapper,
  Surface,
  Typography,
  useTheme,
} from "@isaaczafuta/ui";
import { useEffect } from "react";
import { GoOctoface } from "react-icons/go";
import { Link } from "react-router-dom";

import { NavigationBar } from "../../components/layout/NavigationBar";
import { Page } from "../../components/layout/Page";
import { Pydht } from "../../images/illustrations";

const Items: React.FC = ({ children }) => {
  const { spacing } = useTheme();
  return (
    <div
      className={css`
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: ${spacing.medium};
      `}
    >
      {children}
    </div>
  );
};

const Buttons: React.FC = ({ children }) => {
  const { spacing } = useTheme();
  return (
    <div
      className={css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing.medium};
      `}
    >
      {children}
    </div>
  );
};

interface ItemProps {
  icon?: React.ReactNode;
  name: string;
  description: string;
  link: string;
  github: string;
}

const Item: React.FC<ItemProps> = ({
  icon,
  name,
  description,
  link,
  github,
}) => {
  const { colors, spacing } = useTheme();

  return (
    <Surface>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: ${spacing.medium};
          gap: ${spacing.medium};
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: ${spacing.medium};
          `}
        >
          {icon}
          <Typography variant="h6">{name}</Typography>
        </div>
        <Typography
          className={css`
            flex: 1;
          `}
          color={colors.gray400.main}
        >
          {description}
        </Typography>
        <Buttons>
          <Link to={link}>
            <Button color="secondary">More Info</Button>
          </Link>
          <a href={github}>
            <Button icon={<GoOctoface />}>GitHub</Button>
          </a>
        </Buttons>
      </div>
    </Surface>
  );
};

export const Code: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://github.com/isaaczafuta";
  }, []);

  return <div />;
  return (
    <Page>
      <NavigationBar gutterBottom={false} />

      <ContentWrapper>
        <Typography variant="h4" gutterBottom>
          Projects
        </Typography>
        <Items>
          <Item
            icon={<Pydht width="48px" height="48px" />}
            name="pydht"
            description="Python implementation of the Kademlia DHT data store--a distributed, replicated hash table."
            link="/code/pydht"
            github="https://github.com/isaaczafuta/pydht"
          />
          <Item
            name="pyflacmeta"
            description="A Pure Python3 FLAC Metadata Reader. Reads tags (vorbis comments) from FLAC files."
            link="/code/pyflacmeta"
            github="https://github.com/isaaczafuta/pyflacmeta"
          />
          <Item
            name="whatapi"
            description="A simple python wrapper around the What.cd API. Compatible with other what-like trackers like redacted and apollo."
            link="/code/whatapi"
            github="https://github.com/isaaczafuta/whatapi"
          />
        </Items>
      </ContentWrapper>
    </Page>
  );
};
