import { useState, useCallback, useLayoutEffect } from "react";

export const useMediaQuery = (mediaQuery: string) => {
  const [matches, setMatches] = useState(false);

  const listener = useCallback(
    (e: MediaQueryListEvent) => setMatches(e.matches),
    [setMatches]
  );

  useLayoutEffect(() => {
    if (!window.matchMedia) {
      return;
    }

    const media = window.matchMedia(mediaQuery);
    if (media.matches) {
      setMatches(media.matches);
    }

    media.addEventListener("change", (e) => setMatches(e.matches));
    return () => media.removeEventListener("change", listener);
  }, [mediaQuery, setMatches, listener]);

  return matches;
};
