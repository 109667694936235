import { Dialog, Typography } from "@isaaczafuta/ui";
import { useState } from "react";
import { clearPickleballCredentials } from "../../../api";

interface ClearCredentialsDialogProps {
  username: string;
  onFinished(): void;
  onCancel(): void;
}

export const ClearCredentialsDialog: React.FC<ClearCredentialsDialogProps> = ({
  username,
  onCancel,
  onFinished,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await clearPickleballCredentials();
    switch (response.status) {
      case "success":
        onFinished();
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Clear Credentials"
      cancelEnabled={!submitting}
      confirmEnabled={!submitting}
      error={error}
    >
      <Typography gutterBottom>
        Are you sure you want to clear your credentials? This will suspend any
        recurring reservations.
      </Typography>
      <br />
    </Dialog>
  );
};
