import { Dialog, Typography } from "@isaaczafuta/ui";
import { useState } from "react";
import {
  deletePickleballReservation,
  PickleballReservation,
} from "../../../api";

interface DeleteReservationDialogProps {
  reservation: PickleballReservation;
  onFinished(): void;
  onCancel(): void;
}

export const DeleteReservationDialog: React.FC<
  DeleteReservationDialogProps
> = ({ reservation, onCancel, onFinished }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await deletePickleballReservation(reservation.id);
    switch (response.status) {
      case "success":
        onFinished();
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Delete Scheduled Reservation"
      cancelEnabled={!submitting}
      confirmEnabled={!submitting}
      error={error}
    >
      <Typography gutterBottom>
        Are you sure you want to remove this scheduled reservation? This
        reservation will no longer be automatically booked.
      </Typography>
      <br />
    </Dialog>
  );
};
