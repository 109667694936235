import { Dialog, TextInput, Typography } from "@isaaczafuta/ui";
import { Temporal } from "@js-temporal/polyfill";
import { useEffect, useRef, useState } from "react";
import { addBudgetExpense, BudgetExpense } from "../../../api";
import { CurrencyInput } from "../components/CurrencyInput";

interface AddBudgetExpenseDialogProps {
  onFinished(budgetExpense: BudgetExpense): void;
  onCancel(): void;
}

export const AddBudgetExpenseDialog: React.FC<AddBudgetExpenseDialogProps> = ({
  onCancel,
  onFinished,
}) => {
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleConfirm = async () => {
    setSubmitting(true);
    const response = await addBudgetExpense(
      comment,
      amount,
      Temporal.Now.instant().epochSeconds
    );
    switch (response.status) {
      case "success":
        onFinished(response.data.expense);
        break;
      case "error":
        setError(response.message);
        break;
    }
    setSubmitting(false);
  };

  const currencyInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    currencyInputRef.current?.focus();
  }, []);
  return (
    <Dialog
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Add an Expense"
      cancelEnabled={!submitting}
      confirmEnabled={!submitting}
      error={error}
    >
      <Typography gutterBottom>What did you purchase?</Typography>
      <br />
      <CurrencyInput
        ref={currencyInputRef}
        disabled={submitting}
        label="Amount"
        value={amount}
        onChange={(value) => setAmount(value)}
        gutterBottom
      />
      <TextInput
        disabled={submitting}
        label="Comment"
        value={comment}
        onChange={(value) => setComment(value)}
        gutterBottom
      />
    </Dialog>
  );
};
